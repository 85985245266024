:root {
  --color-primary: #02cb68;
  --color-secondary: #a1a1aa;
  --color-surface: #131313;
  --color-surface-light: rgba(34, 40, 56, 0.5);
  --color-surface-lighter: linear-gradient(0deg, #1a202f 100%, rgba(26, 32, 47, 0) 672.83%);
  --color-text: #fff;
  --color-white: #fff;
  --color-black: #000;
  --color-grey: #222838;
  --color-grey-light: #939db8;
  --color-red: #d93d3d;
  --color-background-secondary: rgba(226, 240, 253, 0.083);

  --color-border: #222838;

  --color-border-input: #2f3647;
  --color-button-secondary: rgb(41, 48, 64);

  --text-gradient: radial-gradient(54.78% 305% at 50% 50%, var(--color-grey-light) 0, var(--color-primary) 100%);

  --border-radius-sm: 0.4rem;

  --shadow-1: rgba(0, 0, 0, 0.1);
  --shadow-2: rgba(0, 0, 0, 0.2);
  --shadow-5: rgba(0, 0, 0, 0.5);
  --shadow-8: rgba(0, 0, 0, 0.8);
  --shadow-inset: rgba(255, 255, 255, 0.5);

  --popup-shadow: 0 12px 28px 0 var(--shadow-2), 0 2px 4px 0 var(--shadow-1);

  --border-radius-s: 0.4rem;

  /* tabs */
  --tabs-bg: #292929;
  --tab-active-bg: #3f3f46;

  --colorSecondaryLight: #1a1a1b;

  /* dashboard */
  --color-dashboard-bg-primary: #fff;
  --color-dashboard-bg-secondary: rgb(0, 0, 0);
  --color-dashboard-bg-third: #fff9ea;

  --color-dashboard-bg-userline: RGB(40, 40, 40);
}

html {
  color: var(--color-text);
  font-size: 16px;
  line-height: 150%;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'SF Pro Display', Helvetica, Arial, sans-serif;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

body {
  background-color: var(--color-surface);
  color: var(--color-text);
  overflow-x: hidden;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 9999px;
}

/* div:hover ::-webkit-scrollbar-thumb {
  background-color: rgba(217,217,227,.6);
} */

::-webkit-scrollbar-thumb {
  /* --tw-border-opacity: 1; */
  opacity: 1;
  background-color: rgba(217, 217, 227, 0.5);
  border-color: rgba(255, 255, 255, 1);
  border-radius: 9999px;
  border-width: 1px;
}

::-webkit-scrollbar {
  height: 1rem;
  width: 0.5rem;
}

/* calendar overrides  */
.react-datepicker {
  width: 100% !important;
  box-sizing: border-box !important;
  box-shadow: 0px 5px 10px rgba(33, 33, 36, 0.1);
  background-color: var(--color-surface) !important;
  border: none !important;
}
.react-datepicker__navigation--next::before {
  content: '\e906';
}
.react-datepicker__month-container {
  padding: 5px;
}
.react-datepicker__month {
  margin: 0 0.4rem 0 0.4rem;
  text-align: center;
}
.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name {
  display: inline-block !important;
  height: 37px !important;
  width: 37px !important;
  line-height: 37px !important;
  text-align: center !important;
  margin: 1px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  border-radius: 50% !important;
}
.react-datepicker__day-name {
  color: var(--color-secondary) !important;
}

.react-datepicker__current-month {
  font-size: 1.6rem;
}
.react-datepicker-time__header,
.react-datepicker__current-month {
  margin-top: 0;
  color: var(--color-text) !important;
  font-weight: 600;
  font-size: 1.2rem;
  height: 2.4rem;
  line-height: 2.4rem;
}
.big-calender .react-datepicker__day--selected {
  color: var(--color-white) !important;
  background-color: var(--color-primary);
}

.react-datepicker__header {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  font-weight: 600;
}

.react-datepicker__time-container {
  border-left: 1px solid var(--color-border-input) !important;
}

.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--color-text) !important;
}
.react-datepicker {
  display: flex;
  font-family: 'SF Pro Display', Helvetica, Arial, sans-serif !important;
}

.react-datepicker__day:hover,
.react-datepicker__time-name:hover {
  color: var(--color-black) !important;
  background-color: var(--tabs-bg) !important;
}

.react-datepicker__time-container {
  background-color: var(--color-surface) !important;
  border-top-right-radius: var(--border-radius-xs);
  border-bottom-right-radius: var(--border-radius-xs);
}
.react-datepicker__time-container .react-datepicker__time {
  background: var(--color-surface) !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  color: var(--color-text) !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: var(--tabs-bg) !important;
  color: var(--color-black) !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 65px;
  top: 10px;
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 78px !important;
}
.react-datepicker__navigation--previous {
  top: 10px !important;
  left: 12px !important;
}

.react-datepicker {
  display: flex !important;
  background: var(--color-surface) !important;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: var(--color-primary) !important;
  color: var(--color-black) !important;
}

.react-datepicker__day--today {
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 2px var(--color-secondary);
  box-sizing: border-box;
}
.react-datepicker__day--today.react-datepicker__day--selected {
  box-shadow: none;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: var(--color-primary) !important;
  color: var(--color-black) !important;
}
.react-datepicker__header {
  background: var(--color-surface) !important;
}
.react-datepicker__day--disabled {
  opacity: 0.3;
}
/* calendar overrides : END */
.turnstile {
  display: none;
}

/* custom select multi */
.select_custom__control {
  background: var(--color-input-bg) !important;
  border: 1px solid var(--color-border-input) !important;
  border-radius: var(--border-radius-s) !important;
}
.select_custom__indicator svg {
  fill: var(--color-grey);
}
.select_custom__indicator-separator {
  background: var(--color-grey) !important;
}
.select_custom__single-value {
  color: var(--color-text) !important;
}
.select_custom__multi-value__remove {
  background-color: var(--color-badge-fail-bg) !important;
}
.select_custom__menu {
  background-color: var(--color-dashboard-bg-secondary) !important;
}

.select_custom__option--is-focused {
  background-color: var(--color-badge-fail-bg) !important;
  color: var(--color-secondary) !important;
}
.select_custom__input {
  color: var(--color-text) !important;
}
.cursor-pointer {
  cursor: pointer;
}

ul li a.active {
  background: var(--color-background-secondary);
  color: #fff;
}

.select_custom--is-disabled {
  opacity: 0.5;
}
