.wrapper {
	box-sizing: border-box;
}

.dashboard {
  background-color: var(--color-dashboard-bg-secondary);
  display: flex;
  & > * {
    flex-grow: 1;
  }
}
.feed {
	overflow-y: auto;
	max-height: 100vh;
	box-sizing: border-box;
	padding-bottom: 5rem;
}
.content {
	box-sizing: border-box;
	padding: 0 2rem;
	max-width: 800px;
	margin: 0 auto;

	p + p {
		margin-top: 1rem;
	}
	p + h2 {
		margin-top: 1rem;
	}
	h2{
		margin-bottom: 1rem;
		font-weight: 600;
	}
}
.header {
	box-sizing: border-box;
	padding: 2rem 1rem;

	h1 {
		font-weight: 600;
		font-size: 1.2rem;
		line-height: 1.4;
		text-align: center;
	}
}