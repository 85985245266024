.navigation {
  display: flex;
  align-items: center;
  gap: 1rem;
  @media screen and (max-width: 768px) {
    display: none;
  }
  ul a {
    box-sizing: border-box;
    display: block;
    padding: 0.3rem 1rem;
    padding-left: 0.3rem;
    border-radius: var(--border-radius-s);
    display: flex;
    align-items: center;
    font-size: 0.875rem;

    svg {
      margin-right: 0.5rem;
    }
    &:hover {
      background-color: var(--color-card-bg);
      color: var(--color-primary);
    }
  }
}
.userSummary {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  img {
    max-width: 30px;
  }
  border-bottom: 1px solid var(--color-border-input);
}
.email {
  font-size: 0.825rem;
  margin: 0.3rem 0;
}
.limits {
  margin-top: 10px;
}
.limit {
  font-size: 0.825rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  b {
    font-weight: 500;
  }
}
.limits span {
  display: block;
  font-size: 0.625rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  color: var(--color-secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active {
  color: var(--color-primary);
}
.userArea {
  display: flex;
  align-items: center;
  position: relative;
  & > div {
    width: 100%;
  }
  img {
    cursor: pointer;
    min-width: 40px;
    vertical-align: bottom;
  }
}

.userContent {
  line-height: 1;
  width: 100%;
}

.userMenus {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  box-sizing: border-box;
  padding: 0.3rem 0.3rem;
  padding-right: 2rem;
  border-radius: 30px;
  cursor: pointer;
  position: relative;
  // background-color: var(--color-dashboard-bg);
  // box-shadow: var(--card-shadow);
}
.pro {
  cursor: pointer;
  background-image: linear-gradient(
    125deg,
    hsl(239deg 77% 70%) 0%,
    hsl(240deg 68% 66%) 11%,
    hsl(241deg 62% 61%) 22%,
    hsl(242deg 56% 57%) 33%,
    hsl(243deg 52% 53%) 44%,
    hsl(244deg 52% 48%) 56%,
    hsl(245deg 58% 44%) 67%,
    hsl(245deg 66% 39%) 78%,
    hsl(246deg 78% 34%) 89%,
    hsl(247deg 100% 29%) 100%
  );
  color: var(--color-white);
  display: flex;
  width: 100%;
  margin-bottom: 3px;
  align-items: center;
  box-sizing: border-box;
  padding: 0.4rem 1rem;
  font-size: 0.875rem;
  border-radius: var(--border-radius-s);
  span {
    display: flex;
    align-items: center;
  }
  img {
    max-width: 30px;
    margin-left: 5px;
  }
}
.icon {
  position: absolute;
  right: 0.5rem;
  top: 1rem;
}
.userName {
  font-size: 0.825rem;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--color-text);
  max-width: 140px;
}
.proBlock {
  margin-left: auto;
  position: relative;
  top: 4px;
}
.creds {
  line-height: 1;
  margin-top: 0.2rem;
  display: flex;
  align-items: center;
  img {
    max-height: 12px;
    min-width: auto;
  }
  span {
    padding: 0.3rem 0.6rem;
    line-height: 1;
    border-radius: 16px;
    border-radius: var(--border-radius-s);
    color: var(--color-primary);
    background-color: rgba(56, 139, 253, 0.1);
    display: flex;
    font-size: 0.785rem;
    align-items: center;
    gap: 0.5rem;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}
.userMenu {
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: var(--border-radius-s);
  background-color: var(--color-card-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  min-width: 120px;
  position: relative;
  z-index: 2;
}
.mobileActionButtons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.theme {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--color-border-input);
  justify-content: space-between;
  gap: 1rem;
  padding-top: 0.5rem;
  p {
    font-size: 0.825rem;
    margin-top: 0;
  }
}
.logoutModal {
  h1 {
    font-size: 1rem;
    font-weight: 600;
  }
}
.actionsFooter {
  border-top: 1px solid var(--color-border-input);
  padding-top: 0.5rem;
  & > div {
    margin-bottom: 0;
  }
  button span {
    color: var(--color-red);
  }
}
.themeSwitch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;

  input {
    height: 22px;
    width: 52px;
    &:before {
      width: 18px;
      top: 2px;
      height: 18px;
      padding: 1px;
    }
    &:checked {
      &::before {
        padding: 1px;
      }
    }
  }
  p {
    margin-top: 0 !important;
    font-size: 0.875rem;
  }
}
.left {
  display: flex;
  align-items: center;
  gap: 1rem;

  a {
    display: flex;
  }
}
.userMenuActions {
  margin-bottom: 0.5rem;
  // border-bottom: 1px solid var(--color-border-input);
  svg {
    margin-right: 0.5rem !important;
  }
  button {
    font-size: 1rem;
    width: 100%;
    padding: 0.3rem 0.2rem;
    &:hover {
      background: var(--color-dashboard-bg-secondary);
    }
  }
}
.delimeter {
  margin: 0.5rem 0;
  height: 1px;
  background-color: var(--color-border-input);
}
.footerLinks {
  margin-top: 0.5rem;
  font-size: 0.825rem;
  display: flex;
  gap: 0.5rem;
}

.muvon {
  margin-top: 0.2rem;
  font-size: 0.725rem;
  margin-bottom: -0.2rem;
  color: var(--color-secondary);
  a {
    text-decoration: underline;
    svg {
      max-height: 18px;
      width: 40px;

      margin-left: 4px;
      vertical-align: text-top;
    }
  }
}
.socials {
  padding-top: 5px;
  display: flex;
  justify-content: space-evenly;
}

.floatingCreds {
  position: absolute;
  left: 14px;
  bottom: 0;
  img {
    max-width: 22px !important;
    min-width: 22px;
  }
}

@media screen and (max-width: 768px) {
  .userMenus {
    padding-right: 0;
  }
}
