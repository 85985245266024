.dashboard {
  box-sizing: border-box;
  h1 {
    font-size: 1.8rem;
    font-weight: bold;
  }
}

.actionsList {
  margin-top: 1.5rem;
}

.apiKeys {
  margin-top: 1.5rem;
}
.keyActions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.card {
  box-sizing: border-box;
  padding: 1rem;
  background: #1e1c1c;
  border-radius: var(--border-radius-s);
  h2 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.table {
  width: 100%;
  thead th {
    color: var(--color-secondary);
    text-transform: uppercase;
    font-size: 0.725rem;
    font-weight: 600;
  }
  tbody {
    td {
      padding: 0.2rem 0;
      color: var(--color-secondary);
      font-size: 0.875rem;
    }
  }

  button {
    &:hover {
      opacity: 0.9;
    }
  }
}

.split {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin-top: 1rem;
  & > div {
    flex-grow: 1;
  }
}

.credits {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);

  h3 {
    font-weight: bold;
    font-size: 2rem;
    color: var(--color-primary);
  }
  span {
    font-size: 0.785rem;
    color: var(--color-secondary);
    line-height: 1;
  }
}
.buy {
  span {
    top: 0.8rem;
    font-size: 0.925rem;
  }
  input {
    height: 50px;
    font-size: 1rem;
  }
  button {
    height: 50px;
    margin-right: -10px;
    width: 60px;
    justify-content: center;
  }
  button span {
    padding-top: 0;
    margin-top: 0;
    color: #fff;
  }
}

.buyDetails {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  p {
    font-size: 0.825rem;
    color: var(--color-secondary);
  }
}

@media screen and (max-width: 768px) {
  .card {
    overflow-x: auto;
    table th {
      white-space: nowrap;
      padding: 0 10px;
    }
    table td {
      padding: 0 10px;
    }
  }
  .split {
    flex-direction: column;
    .card {
      width: 100%;
    }
  }
}

.more {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.tableActions {
  margin-top: 1rem;
  button {
    text-align: center;
    justify-content: center;
    span {
      text-align: center;
    }
  }
}
