.surface {
  background: var(--modal-surface);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  backdrop-filter: blur(4px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  display: inline-block;
  max-height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}

.closeButton {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.modal {
  background: var(--tabs-bg);
  border-radius: var(--border-radius-s);
  box-shadow: var(--popup-shadow);
  box-sizing: border-box;
  padding: 1rem;
  // max-width: 350px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  min-width: 300px;
  position: relative;
  align-self: center;
  @media screen and (max-width: 768px) {
    margin: 0 1rem;
  }
}
.icon {
  margin-bottom: 0.5rem;
}
.title {
  font-size: 1.15rem;
  font-weight: 500;
}

.subtitle {
  margin-top: 0.5rem;
  color: var(--color-secondary);
}

.action {
  margin-top: 1rem;
  box-sizing: border-box;
  display: flex;
}
.action button + button {
  margin-left: 0.5rem;
}
.action button {
  flex-grow: 1;
  text-align: center;
  justify-content: center;
}
