.centered {
  display: flex;
  height: 100vh;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  a {
    margin-top: 10px;
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
  }
}
