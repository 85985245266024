.hero {
  box-sizing: border-box;
  padding: 5rem 1rem;
  //background-image: url('../../assets/images/bg-3.svg');

  padding-top: 10rem;
  position: relative;
  h1 {
    font-size: 3.5rem;
    line-height: 1.2;
    font-weight: 600;
    text-align: center;
    //color: rgb(15, 23, 42);
    span {
      font-size: 4.5rem;
      color: var(--color-primary);
    }
  }
  p {
    color: var(--color-secondary);
    margin-top: 2rem;
    line-height: 1.6;
    font-size: 1.425rem;
    text-align: center;
  }
}
.surface {
  position: relative;
  background-image: url('https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/3d76836c-281c-4c3e-7d41-696d2e41f700/large');
  background-size: cover;
  background-repeat: no-repeat;
  top: -20px;
  //background-position: -50px 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(19, 19, 19, 0.62) 0%, rgba(19, 19, 19, 0.999) 100%);
  }
}
.featuresList {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 2rem;

  img {
    border-radius: var(--border-radius-s);
    box-shadow: 0 1px 12px 2px #0a001f0d;
    height: 368px;
    width: 100%;
    object-fit: cover;
  }

  h3 {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  p {
    color: var(--color-secondary);
    line-height: 1.4;
    margin-top: 0.8rem;
    font-size: 1.2rem;
  }
}

.flexRow {
  display: flex;
  gap: 2.5rem;
  width: 100%;
  & > div {
    flex-grow: 1;
  }
}

@media screen and (max-width: 768px) {
  .featuresList,
  .flexRow {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .featureItem {
    display: flex !important;
    flex-direction: column;
    width: 100% !important;
    h3 {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
    p {
      font-size: 1.1rem;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}
.featureItem {
  //overflow: hidden;
  width: 50%;
  //background-color: var(--color-dashboard-bg);

  margin-bottom: 2rem;
}
.featureItemText {
  box-sizing: border-box;
  padding: 1rem;
  text-align: left;
  h3,
  p {
    text-align: left;
  }
}
.professions {
  margin: 0 auto;
  margin-top: 2rem;
  //max-width: 940px;
  grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
  display: grid;
  gap: 4rem;
}
.stackedAvatars {
  display: flex;
  @media screen and (max-width: 768px) {
    p {
      font-size: 0.875rem !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .professions {
    gap: 2rem;
  }
  .profession {
    margin-bottom: 0rem !important;
  }
}
.earnContent {
  max-width: 50%;
}
.earn {
  display: flex;
  align-items: center;
  gap: 5rem;
  margin-top: 3rem;
  margin-bottom: 5rem;
  h2 {
    font-weight: 600;
    font-size: 2.2rem;
    margin-bottom: 1rem;
    line-height: 2.6rem;
  }
  p {
    color: var(--color-secondary);
    font-size: 1rem;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .earn {
    flex-direction: column;
    gap: 2rem;
  }
  .earnContent {
    max-width: 100%;
    width: 100%;
  }
}

.earnImage {
  video {
    max-width: 100%;
    height: auto;
    border-radius: 26px;
  }
  @media screen and (max-width: 768px) {
    iframe {
      max-width: 100%;
      height: 240px;
    }
  }
}

.professionContent {
  width: 450px;
  h3 {
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  p {
    color: var(--color-secondary);
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5;
  }
}
.professionSuggest {
  display: flex;
  flex-direction: column;
  display: none;
}
.example {
  box-sizing: border-box;
  padding: 0.5rem;

  display: inline-block;
  border-radius: var(--border-radius-s);
  box-shadow: var(--card-shadow);
  border: 1px solid var(--color-card-border);
  background-color: var(--color-card-bg);
  font-size: 0.8rem;
}

.professionImage {
  max-width: 280px;
  img {
    max-width: 100%;
    height: auto;
  }
}

.profession {
  margin-bottom: 3rem;
  align-items: center;
  display: flex;
  gap: 3rem;

  &:nth-child(2n) {
    justify-content: end;
    // & > div:first-child {
    //   order: 3;
    // }
  }
}
.bonus {
  font-size: 0.75rem;
  color: var(--color-secondary);
  text-align: center;
}
.docs {
  button span {
    color: var(--color-secondary);
  }
}
.form {
  max-width: 500px;
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  button {
    height: 45px;
    svg {
      margin-left: 10px !important;
    }
    span {
      font-size: 1rem;
    }
  }
  p {
    text-align: center;
    margin-top: 0.5rem;
    font-size: 0.825rem;
    color: var(--color-secondary);
  }
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      margin-left: 0.5rem;
    }
  }
}

.launch {
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  span {
    display: inline-block;
    box-sizing: border-box;
    padding: 0.1rem 0.75rem;
    background: var(--color-background-secondary);
    font-size: 0.8rem;
    letter-spacing: 0.2px;
    font-weight: 500;
    border-radius: var(--border-radius-sm);
  }
}
.trendList {
  margin-top: 1.5rem;
  display: grid;
  gap: 1rem;
  // max-height: 100%;
  // overflow-y: auto;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  padding-bottom: 5rem;
  grid-auto-rows: 0fr;
}
.others {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    margin-left: 1rem;
    font-size: 0.925rem !important;
    color: var(--color-secondary);
    margin-top: 0;
  }
  img {
    max-height: 36px;
    border-radius: 50%;
    margin: 0 -6px;
    vertical-align: middle;
    border: 2px solid var(--color-surface);
  }
}
.trend {
  margin-top: 5rem;
  h3 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    a {
      color: var(--color-primary);
      border-bottom: 2px dashed var(--color-primary);
      margin-right: 5px;
    }
  }
  h3 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    line-height: 1.8rem;
    margin: 0 auto;
    text-align: center;
    margin-top: 1rem;
  }
}
.advantages {
  h2 {
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    a {
      color: var(--color-primary);
      border-bottom: 2px dashed var(--color-primary);
      margin-right: 5px;
    }
  }
  h2 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin: 0 auto;
    text-align: center;
    margin-top: 1rem;
  }
}
.advantagesList {
  margin-top: 2rem;
}
.advantagesCenter {
  width: 26%;
  min-width: 26%;
  margin: 0 2rem;
  img {
    width: 100%;
    height: auto;
    position: sticky;
    top: 20px;
  }
}
.advantage {
  margin-bottom: 1.5rem;
}
.advantage h3 {
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: var(--color-secondary);
}
.promptList {
  display: flex;
  gap: 1.4rem;
}
.producthunt {
  text-align: center;
  p {
    margin-top: 0rem;
    font-size: 0.825rem;
  }
}
.prompt {
  box-sizing: border-box;
  padding: 1.1rem;
  width: 25%;
  max-width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1rem;
  font-weight: 500;

  border-radius: var(--border-radius-s);
  background-color: var(--color-advantage-item);
  p {
    margin-top: 0.5rem;
  }
  span {
    display: inline-block;
    text-transform: uppercase;
    color: var(--color-primary);
    padding: 0.2rem 0.6rem;
    line-height: 1;
    border-radius: var(--border-radius-s);
    font-size: 0.725rem;
    font-weight: 500;
    background-color: rgba(63, 136, 197, 0.2);
  }
}

.professionBadge {
  display: inline-block;
  text-transform: uppercase;
  color: var(--color-primary);
  padding: 0.2rem 0.6rem;
  line-height: 1;
  border-radius: var(--border-radius-s);
  font-size: 0.725rem;
  font-weight: 500;
  background-color: rgba(63, 136, 197, 0.2);
}

.more {
  margin-top: 3rem;
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 5rem;
}

.cost {
  @media screen and (max-width: 769px) {
    & section div:nth-child(2) {
      max-height: initial;
    }
  }
}
.advantagesRight,
.advantagesLeft {
  width: 37%;
}
.advantageItem {
  box-sizing: border-box;
  padding: 1.2rem;
  margin: 1rem 0;
  border-radius: var(--border-radius-s);
  background-color: var(--color-advantage-item);
  width: 100%;
  align-self: flex-start;
  span {
    font-weight: 600;
    line-height: 1.4rem;
    display: block;
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.3rem;
    line-height: 1.4rem;
  }
}

.chart {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
  }
  h2 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
}
.chartContent {
  max-width: 30%;
  min-width: 30%;
  margin-right: 2rem;
}
.chartContainer {
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
}

.calculator {
  margin-top: 5rem;

  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
  }
  h2 + p {
    color: var(--color-secondary);
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
}
.calculatorContainer {
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
}
.calculatorContainerText {
  width: 40%;
  margin-right: 2rem;
  span {
    display: block;
    margin-top: 1.5rem;
    font-size: 0.755rem;
    color: var(--color-secondary);
    line-height: 1.2rem;
  }
}
.calculatorContainerForm {
  box-sizing: border-box;
  padding: 1.2rem;
  width: 450px;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  span {
    padding-top: 0;
  }
}

.duration {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.durationActions {
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin: 0 0.4rem;
    text-align: center;
    background: var(--color-border-input);
    color: var(--color-text);
    cursor: pointer;
  }
}

button.selectedDay {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.calculatorTotal {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 1rem;
    color: var(--color-secondary);
  }
  p {
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--color-primary);
  }
  button {
    span {
      font-size: 1rem !important;
      color: var(--color-white) !important;
    }
  }
}

.faq {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h2 + p {
    color: var(--color-secondary);
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
  }
}

.faqList {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.faqItem {
  width: 30%;
  margin-bottom: 2rem;
  span {
    font-weight: bold;
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.3rem;
  }
}

.blog {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h2 + p {
    color: var(--color-secondary);
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
  }
}

.blogList {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.blogItem {
  width: 30%;
  margin-bottom: 2rem;
  img {
    border-radius: var(--border-radius-s);
    margin-bottom: 0.5rem;
    max-width: 100%;
  }
  a {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.4;
    &:hover {
      color: var(--color-primary);
    }
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.3rem;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 750px) {
  .advantagesList {
    display: block;
  }
  .advantagesLeft {
    width: 100%;
  }
  .advantagesCenter {
    width: 100%;
    text-align: center;
    margin-left: 0;
    img {
      max-width: 300px;
      margin: 0 auto;
    }
  }
  .advantagesRight {
    width: 100%;
  }

  .professionImage {
    height: auto;
    order: -1;
  }
  .professionContent {
    width: 100%;
    max-width: 100%;
  }
  .profession {
    flex-direction: column;
    gap: 1rem;
  }
  .more {
    margin-top: 1rem;
  }
}

@media screen and (max-width: 640px) {
  .hero {
    padding: 3rem 1rem;
  }
  .promptList {
    overflow-x: auto;
  }
  .prompt {
    width: 250px;
    max-width: 250px;
    margin-bottom: 1rem;
    min-width: 250px;
  }
  .advantages {
    margin-top: 2rem;
    h2 {
      font-size: 1.9rem;
      font-weight: 600;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }

  .faq {
    margin-top: 3rem;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
      text-align: center;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      text-align: center;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
  .faqItem {
    width: 47%;
  }

  .calculator {
    margin-top: 3rem;

    h2 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }
  .calculatorContainer {
    display: block;
  }
  .calculatorContainerText {
    width: 100%;
    margin-bottom: 2rem;
  }
  .calculatorContainerForm {
    width: 100%;
  }

  .chart {
    margin-top: 3rem;
    display: block;
    h2 {
      font-size: 1.6rem;
      font-weight: 600;
    }
    h2 + p {
      color: var(--color-secondary);
      margin-top: 1rem;
      max-width: 750px;
      font-size: 1rem;
      line-height: 1.6rem;
    }
  }

  .chartContent {
    width: 100%;
    margin-bottom: 2rem;
    max-width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .hero h1 {
    font-size: 9vw;
    margin-top: 1rem;
    span {
      font-size: 14vw;
      line-height: 14vw;
      margin-bottom: 1rem;
    }
  }
  .hero p {
    font-size: 4vw;
    br {
      display: none;
    }
  }
  .hero {
    padding-top: 8rem;
  }

  .form p {
    font-size: 3vw;
  }

  .advantages h2 {
  }

  .faqItem {
    width: 100%;
  }
}

@media screen and (max-width: 460px) {
  .calculatorContainerForm span {
    font-size: 0.925rem;
  }
  .durationActions button {
    margin: 0 1vw;
    width: 11vw;
    height: 11vw;
  }
  .chartContainer {
    padding: 0;
  }
}

html:lang(de) {
  @media screen and (max-width: 460px) {
    .hero h1 {
      font-size: 9vw;
    }
    .hero p {
      font-size: 3.1vw;
    }
  }
}

.video {
  max-width: 860px;
  margin: 0 auto;
  video {
    border-radius: 12px;
    box-shadow: rgba(10, 0, 31, 0.05) 0px 1px 24px 4px;
    max-width: 100%;
    width: 100%;
  }
}

.try {
  margin-bottom: 5rem;
  margin-top: 5rem;
  text-align: center;
  h3 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  p {
    max-width: 750px;
    font-size: 1.1rem;
    margin: 0 auto;
    line-height: 1.8rem;
    color: var(--color-secondary);
  }
  button {
    align-self: center;
    display: inline-block;
    margin-top: 2rem;
    span {
      font-size: 1.2rem;
    }
  }
}
.newFeaturesList {
  margin-top: 3rem;
}
.codeExample {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.codeBlock {
  margin-top: 1rem;
  width: 100%;
}
.newFeatureItem {
  backdrop-filter: blur(4px);
  background: var(--tabs-bg);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  padding: 4rem;
  gap: 2rem;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  margin-bottom: 2rem;
  h3 {
    font-size: 2rem;
    line-height: 2.6rem;
    font-weight: bold;
  }
  p {
    margin-top: 1rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: var(--color-secondary);
  }
}
.newFeatureItemImage {
  position: absolute;
  bottom: -5px;
  top: 15%;
  border-radius: var(--border-radius-s);
  right: 0;
  overflow: hidden;
  width: 45%;
  img {
    width: 100%;
    height: 100%;
    object-position: top;
    object-fit: cover;
  }
}
.newFeatureItemContent {
  max-width: 50%;
}
.newFeatureItemContentAction {
  margin-top: 2.5rem;
  a {
    display: inline-block;
  }
  button {
    span {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .newFeatureItem {
    flex-direction: column;
    padding: 2rem 1rem;
    h3 {
      font-size: 2.2rem;
      line-height: 2.6rem;
      font-weight: bold;
    }
  }
  .newFeatureItemContent {
    max-width: 100%;
    width: 100%;
  }
  .newFeatureItemImage {
    position: static;
    order: -1;
    width: 100%;
    border-radius: 20px;
    margin-top: -1rem;
  }
  .trend h3 a {
    font-size: 2.2rem;
  }
  .trend {
    margin-top: 2rem;
  }
}

.students {
  text-align: center;
  margin-top: 2rem;
  p {
    font-weight: 500;
  }
}
.list {
  margin-top: 1rem;
  text-align: center;
  img {
    width: 450px;
    height: auto;
    filter: contrast(10%);
  }
}

.reviews {
  margin-top: 5rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h2 + p {
    color: var(--color-secondary);
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
  }
}

.reviewsList {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.reviewItem {
  box-sizing: border-box;
  padding: 1rem;
  width: calc(33% - 1rem);
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p {
    font-weight: 500;
  }
}
.reviewItemAuthor {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  span {
    display: flex;
    align-items: center;
    color: var(--color-secondary);
    font-size: 0.875rem;
  }
  img {
    margin-right: 5px;
  }
}

@media screen and (max-width: 768px) {
  .reviewItem {
    width: 100%;
  }
}

.download {
  margin-top: 3rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  span {
    font-weight: bold;
    font-size: 1.2rem;
  }
}
.downloadList {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}
.downloadItem {
  box-sizing: border-box;
  max-width: 160px;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
}

.codeExample {
  & > div {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .download {
    display: none;
  }
  .blogList {
    flex-direction: column;
  }
  .blogItem {
    width: 100%;
  }
  .codeExample {
    & > div {
      max-width: 100%;
    }
  }
}

.muvon {
  font-size: 0.725rem;
  justify-content: center;
  display: flex;
  margin-bottom: 0.5rem;
  color: var(--color-secondary);
  svg {
    max-height: 18px;
    width: 50px;

    margin-left: 4px;
    vertical-align: text-top;
  }
}

.ph {
  display: flex;
  justify-content: center;
  gap: 1rem;
}
.features {
  margin-top: 5rem;
}
.featuresTitle {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  @media screen and (max-width: 550px) {
    font-size: 1.2rem;
    font-weight: 600;
  }
}
.featuresList {
  grid-template-columns: 1fr;
  gap: 10px;
}
.featuresItem {
  width: 100%;
  color: var(--color-secondary);
  text-align: center;
}

.listItem {
  position: relative;
  aspect-ratio: 1 / 1;
  display: flex;
  min-width: 250px;
  width: 250px;
  border-radius: 8px;
  border-radius: var(--border-radius-s);
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-card-border);
  box-shadow: var(--card-shadow);
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    .floatBox {
      opacity: 1;
    }
  }
  @media screen and (max-width: 768px) {
    min-width: 150px;
    width: 150px;
  }
}

.grid {
  margin-top: 5rem;
}
