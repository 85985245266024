.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  height: 100vh;
  h1 {
    font-weight: bold;
    font-size: 2rem;
  }
  p {
    color: var(--color-secondary);
    margin-top: 1rem;
  }
  a {
    margin-top: 2rem;
    display: inline-block;
    border-bottom: 1px dashed var(--color-text);
  }
}
