.footer {
  background-color: #0a0a0a;
  padding: 1rem 0;
  color: var(--color-secondary);
  border-top: 1px solid var(--color-border);
  font-size: 14px;
  // margin-top: 5rem;
  p {
    display: flex;
    align-items: center;
    a {
      display: flex;
    }
    svg {
      max-height: 26px;
    }
  }
}

.footerTop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 1rem 0;
}
.nav {
  display: flex;
  align-items: flex-start;
  ul {
    margin-right: 4rem;
    li {
      margin-bottom: 0.3rem;
    }
  }
}

.form {
  margin-top: -0.5rem;
  form {
    display: flex;
    align-items: center;
    width: 310px;
    button {
      margin-left: 0.5rem;
    }
  }
}

.footerBottom {
  box-sizing: border-box;
  padding: 1rem 0;
  border-top: 1px solid var(--color-border-input);
  padding-bottom: 0;
  svg {
    --logo-color1: #c8c8c8;
    --logo-color2: #ececec;
    --logo-color3: #dcdcdc;
    --logo-color4: #c8c8c8;
    --logo-text: #dcdcdc;
    --color-text: #fff;
    width: 140px;
  }
  div {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 640px) {
  .footerTop {
    display: block;
  }
  .form {
    margin-top: 2rem;
  }
}
