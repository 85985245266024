.dashboard {
  box-sizing: border-box;
}

.hero {
  box-sizing: border-box;
  max-width: 980px;
  margin: 0 auto;
  padding: 5rem 1rem;
  //background-image: url('../../assets/images/bg-3.svg');

  padding-top: 10rem;
  position: relative;
  h1 {
    font-size: 4.5rem;
    line-height: 1.2;
    font-weight: 600;
    text-align: center;
    //color: rgb(15, 23, 42);
    span {
      color: var(--color-primary);
    }
  }
  p {
    color: var(--color-secondary);
    margin-top: 2rem;
    line-height: 1.6;
    font-size: 1.425rem;
    text-align: center;
  }
}
.surface {
  position: relative;
  background-image: url('https://imagedelivery.net/xBo-ZS_SWUoleWeSRokYNQ/d496ca6b-9177-4adf-6282-6b9d136dac00/4k');
  background-size: cover;
  background-repeat: no-repeat;
  top: -20px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(19, 19, 19, 0.56) 0%, rgba(19, 19, 19, 0.999) 100%);
  }
}

.content {
  max-width: 720px;
  margin: 0 auto;
  margin-top: 1rem;
  position: relative;
  z-index: 2;

  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 5rem;
  a {
    color: var(--color-primary);
  }
  pre code {
    font-size: 0.875rem;
  }
  img {
    max-width: 100%;
  }
  p {
    margin-bottom: 1rem;
    white-space: pre-line;
  }
  h1 {
    margin-bottom: 0.7rem;
    font-weight: 600;
    font-size: 1.4rem;
  }
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 0.7rem;
  }
  table {
    margin-bottom: 1rem;
    border-collapse: collapse;
    width: 100%;
    font-size: 0.925rem;
    border-radius: var(--border-radius-s);
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    tr:nth-child(even) {
      background-color: var(--color-dashboard-bg-secondary);
    }
    tr:hover {
      background-color: var(--color-dashboard-bg-secondary);
    }
    th,
    td {
      padding: 0.6rem 15px;
      text-align: left;
    }
    th {
      color: var(--color-white);

      padding: 12px 15px;
      text-align: left;
      font-weight: 600;
      background-color: var(--color-secondary);
    }
  }
}

.content ul,
.content ol {
  padding: 1.2rem;
  padding-top: 0;
  list-style: unset;
  padding-left: 3.2rem;
}
.content ol {
  list-style: numeric;
}
.content li + li {
  margin-top: 0.5rem;
}

.date {
  margin-bottom: 1rem;
  font-size: 0.725rem;
  color: var(--color-secondary);
}

@media screen and (max-width: 768px) {
  .hero {
    padding: 2rem 0;
    padding-top: 8rem;
  }
  .hero h1 {
    font-size: 2.2rem;
  }
  .hero p {
    font-size: 1.1rem;
  }
}

.blog {
  margin-top: 10rem;
  margin-bottom: 10rem;
  h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
  h2 + p {
    color: var(--color-secondary);
    margin: 0 auto;
    margin-top: 1rem;
    max-width: 750px;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
  }
}

.blogList {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.blogItem {
  width: 30%;
  margin-bottom: 2rem;
  @media screen and (max-width: 550px) {
    width: 100%;
    margin-bottom: 2rem;
  }
  img {
    border-radius: var(--border-radius-s);
    margin-bottom: 0.5rem;
    max-width: 100%;
  }
  a {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.4;
    &:hover {
      color: var(--color-primary);
    }
  }
  p {
    color: var(--color-secondary);
    margin-top: 0.3rem;
  }
}

.try {
  margin-bottom: 5rem;
  margin-top: 5rem;
  text-align: center;
  h3 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 0.5rem;
  }
  p {
    max-width: 750px;
    font-size: 1.1rem;
    margin: 0 auto;
    line-height: 1.8rem;
    color: var(--color-secondary);
  }
  button {
    align-self: center;
    display: inline-block;
    margin-top: 2rem;
    span {
      font-size: 1.2rem;
    }
  }
}
