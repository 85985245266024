.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}
.md {
  width: 40px;
  height: 40px;
}

.sm {
  width: 30px;
  height: 30px;
}
.xl {
  width: 50px;
  height: 50px;
}

.xs {
  width: 20px;
  height: 20px;
}
