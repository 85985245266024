.dashboard {
  box-sizing: border-box;
  margin-bottom: 10rem;
  h1 {
    font-size: 1.8rem;
    font-weight: bold;
  }
}
.docsContent {
  margin-top: 2rem;
  h2 {
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    margin-top: 5rem;
  }
  h3 {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  p {
    color: var(--color-secondary);
    font-size: 14px;
    line-height: 20px;
  }
  p + p {
    margin-bottom: 0.5rem;
  }
  p + h2 {
    margin-top: 4rem;
  }
  p + h3 {
    margin-top: 2rem;
  }
  a {
    color: var(--color-primary);
    border-bottom: 1px dashed var(--color-primary);
    &:hover {
      border-bottom: 0;
    }
  }

  table {
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-collapse: collapse;
    width: 100%;
    font-size: 14px;
    border-radius: var(--border-radius-s);
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    tr:nth-child(even) {
      background-color: var(--color-dashboard-bg-secondary);
    }
    tr:hover {
      background-color: var(--color-dashboard-bg-secondary);
    }
    th,
    td {
      padding: 0.6rem 15px;
      text-align: left;
    }
    th {
      color: var(--color-white);

      padding: 12px 15px;
      text-align: left;
      font-weight: 600;
      background: var(--tab-active-bg);
    }
  }
}

.codeExample {
  // margin-top: 1rem;
  pre {
    margin: 0;
  }
}

.sectionTitle {
  background: hsla(44deg, 6%, 50%, 0.15);
  border-radius: var(--border-radius-s);
  padding: 0.1rem 0.4rem;
}

.codeBlock {
  background: hsla(44, 6%, 50%, 0.15);
  border-radius: 3px;
  color: #eb5757;
  font-family: inherit;
  font-size: 14px;
  // padding: 0.2em 0.4em;
}

.code {
  // margin-bottom: 2rem;
}

.docsContent ul,
.docsContent ol {
  padding: 1.2rem;
  padding-top: 0;
  list-style: unset;
  padding-left: 3.2rem;
}
.docsContent ol {
  list-style: numeric;
}
.docsContent li + li {
  margin-top: 0.5rem;
}

.codeExampleBox {
  margin-top: 1rem;
  ul {
    padding-bottom: 0;
    padding: 0;
  }
}

.noteBigInt {
  margin-top: 1rem;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-s);
  color: #fff;
  background: var(--color-red);
}

.contentBox {
  // padding-top: 5rem;
  display: grid;
  grid-template-columns: 1fr 1fr; /* Два столбца равной ширины */
  gap: 20px; /* Расстояние между блоками */
  align-items: start;
  h2 {
    margin-top: 0;
  }
  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
}
.contentBoxCode {
  background-color: var(--color-dashboard-bg-secondary);
  height: 100%;
  overflow-x: auto;
  margin-bottom: 10rem;
  h3,
  p,
  ul {
    margin-left: 0.5rem;
  }
}

.contentBoxText {
  // background-color: var(--color-dashboard-bg-secondary);
  box-sizing: border-box;
  height: 100%;
  margin-bottom: 7rem;
  // padding: 20px;
  h3 span {
    background: rgba(0, 255, 0, 0.3);
    margin-left: 1rem;
  }
}
