.dashboard {
  display: flex;
}

.header {
  width: 250px;
  max-width: 250px;
  border-right: 1px solid var(--tabs-bg);
}
.headerWrapper {
  box-sizing: border-box;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.dashboardContainer {
  flex-grow: 1;
  max-height: 100vh;
  min-height: 100vh;
  overflow-y: auto;
}
.nav {
  margin-top: 2rem;

  a {
    display: block;
    margin: 0.1rem 0;
    color: var(--color-secondary);
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius-s);
    &:hover {
      background: var(--color-background-secondary);
      color: #fff;
    }
  }
}

.dashboardContainer {
  box-sizing: border-box;
  padding: 1rem 2rem;
}
.userAreaMobile {
  display: none;
}
@media screen and (max-width: 768px) {
  .dashboard {
    flex-direction: column;
  }
  .header {
    width: 100%;
    max-width: 100%;
  }
  .headerWrapper {
    height: auto;
  }
  .nav {
    margin-top: 1rem;
  }
  .dashboardContainer {
    padding: 1rem;
    overflow-y: unset;
  }
  .userAreaMobile {
    display: block;
  }
  .headerMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
      & > div {
        display: block;
      }
    }
  }
}
.submenu {
  margin-top: 1rem;
  padding-left: 1.5rem;
  li {
    font-weight: 500;
    font-size: 14px;
    color: var(--color-secondary);
    a {
      color: #fff;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;
      font-weight: 400;
    }
  }
}
