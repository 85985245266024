.logo {
  display: flex;
  align-items: center;
  img {
    margin-right: 0.6rem;
    max-height: 44px;
  }
  a {
    font-size: 1.5rem;
    font-weight: 600;
  }
  p {
    font-weight: 600;
    letter-spacing: -1.5px;
    font-size: 1.5rem;
    color: var(--color-text) !important;
  }
}
