.textarea {
  font-family: inherit;
  appearance: none;
  width: 100%;
  display: block;
  -webkit-appearance: none;
  border-width: 1px;
  // padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  line-height: 38px;
  padding-bottom: 0.5rem;
  background: var(--color-input-bg);
  font-size: 0.975rem;
  height: 38px;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--color-border-input);
  color: var(--color-text);
  box-shadow: var(--shadow-input);
  resize: none;
  max-height: 250px;
}

.textarea::placeholder {
  color: var(--color-secondary);
}

.textarea:disabled {
  background: var(--input-disabled-bg);
  color: var(--color-secondary);
}

.textarea:focus {
  outline: 2px solid var(--color-primary);
  outline-offset: -1px;
}
.description {
  color: var(--color-secondary);
  line-height: 1.4;
  font-size: 0.875rem;
  margin-bottom: 0.1rem;
}
.label {
  color: var(--color-text);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
  svg {
    margin-left: 0.2rem;
  }
}

.error {
  border-color: var(--color-red);
}
