.formContainer {
  box-sizing: border-box;
  padding: 1rem 0;
  width: 300px;
  // height: 200px;
  @media screen and (max-width: 769px) {
    width: 100%;
  }

  form {
    input {
      text-align: left;
    }
    button {
      margin-top: 0.5rem;
      width: 100%;
      justify-content: center;
      span {
        font-weight: bold;
        font-size: 0.825rem;
        text-align: center;
      }
    }
  }
  p {
    margin-bottom: 1.5rem;
    color: var(--color-secondary);
  }
}
.newPerson {
  box-sizing: border-box;
  padding: 0.3rem 0.5rem;
  margin-top: -1rem;
  color: var(--color-primary);
  background-color: rgba(56, 139, 253, 0.1);
  border-radius: var(--border-radius-s);
  margin-bottom: 0.5rem;

  span {
    font-weight: 500;
  }
}
.action {
  margin-top: 0 !important;
  display: flex;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}
.title {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.temp {
  margin-top: 1rem;
  p {
    display: block;
    text-align: center;
    margin: 0.5rem 0;
    color: var(--color-secondary);
  }
}
